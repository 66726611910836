<template>
  <base-material-card icon="mdi-clipboard-text" :title="$t('menu.unitType.list')" class="px-5 py-3">
    <base-add-button
      :permissions="permissions"
      :basePath="basePath"
      :title="$t('unitCategory.add')"
      :params="addBtnParams"
    >
    </base-add-button>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="text-center primary--text">
            {{ $t('general.actions') }}
          </th>
          <!--          <th class="primary&#45;&#45;text">-->
          <!--            ID-->
          <!--          </th>-->
          <th class="primary--text">
            {{ $t('unitType.name') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('unitType.availableUnits') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.uuid">
          <td>
            <div class="d-flex justify-center align-center">
              <base-edit-button
                :permissions="permissions"
                :basePath="basePath"
                :id="item.uuid"
              ></base-edit-button>
              <base-delete-button
                :permissions="permissions"
                :id="item.uuid"
                :showIcon="true"
                @deleteAction="deleteAction"
              ></base-delete-button>
            </div>
          </td>
          <!--          <td>{{ index + 1 }}</td>-->
          <td>{{ item.name }}</td>
          <td class="text-center">{{ item.total_available_units }}</td>
          <td>
            <base-detail-button
              :permissions="permissions"
              :basePath="basePath"
              :id="item.uuid"
            ></base-detail-button>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  props: ['items', 'meta', 'permissions', 'projectUuid'],
  components: {
    Pagination,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.unitType.basePath,
    }),
    addBtnParams() {
      return {
        project_uuid: this.projectUuid,
      };
    },
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    async deleteAction(uuid) {
      console.log('DELETE UNIT TYPE UUID: ', uuid);
      try {
        await this.$store.dispatch('unitType/delete', uuid);
        await this.$store.dispatch('unitType/removeUnitTypeListByUuid', uuid);
        this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('unitType.delete.successMsg'),
          'success',
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
